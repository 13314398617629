<template>
    <div class="p-support full_page">
        <projectIsUnderConstructionWarning></projectIsUnderConstructionWarning>
        <nav class="layout_nav support_nav" :class="{'admin_style': authIsAdmin}">
            <v-container>
                <ul>
                    <li>
                        <router-link :to="{name: 'faq'}">About/FAQ</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'changelog'}">Changelog</router-link>
                    </li>

                    <template v-if="!authIsAdmin">
                        <li>
                            <router-link :to="{name: 'feature-suggestions'}">Suggest a feature</router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'bug-reports'}">Report a bug</router-link>
                        </li>
                    </template>

                    <template v-if="authIsAdmin">
                        <li>
                            <router-link :to="{name: 'feature-suggestions'}">Suggestions</router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'reports'}">Reports</router-link>
                        </li>
                        <li>
	                        <router-link :to="{ name: 'verifications', params: { status: 'submitted' || undefined } }">Verifications</router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'admin-settings'}">Settings</router-link>
                        </li>
                    </template>

                </ul>
            </v-container>
        </nav>
        <slot></slot>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import projectIsUnderConstructionWarning from "@/components/projectIsUnderConstructionWarning.vue";

export default {
    name: "supportLayout",
    components: {
        projectIsUnderConstructionWarning
    },
    data() {
        return {
            authIsAdmin: false
        }
    },
    computed: {
        ...mapGetters(['AUTH_DATA']),
    },
    created() {
        this.authIsAdmin = this.AUTH_DATA.userRoles.includes('ROLE_ADMIN');
    }
}
</script>

<style lang="scss">

</style>